import React from "react";
import theme from "../theme";
import { Box } from "@mui/material";
import { Loading } from "react-loading-dot";

const styles = {
  spinner: {
    position: "absolute",
    width: "80px",
    height: "9px",
    marginTop: "15px",
    left: "50%",
    display: "inline-block",
    transform: "translate(-50%, -50%)",
  },
};

const LoadingEllipsis = () => {
  return (
    <Box sx={styles.spinner}>
      <Loading
        dots={3}
        background={theme.palette.grey.white}
        size="0.6rem"
        margin="0.3rem"
      />
    </Box>
  );
};

export default LoadingEllipsis;
