import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import LoadingEllipsis from "./LoadingEllipsis";
import { Link } from "gatsby";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import theme from "../theme";

const opacity = 66; //66 means 40% opacity. Check this link for more info: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
const getColors = (look) => {
  switch (look) {
    case "green":
      return {
        bgColor: `${theme.palette.primary.main} !important`,
        bgColorHover: `${theme.palette.grey.whit} !important`,
        textColor: `${theme.palette.black.darkSeaBlack} !important`,
        textColorHover: `${theme.palette.black.darkSeaBlack} !important`,
        border: `${theme.palette.primary.main} !important`,
        borderColorHover: `${theme.palette.black.darkSeaBlack} !important`,
      };
    case "greenDisabled":
      return {
        bgColor: `${theme.palette.primary.main}${opacity}`,
        textColor: `${theme.palette.black.darkSeaBlack}${opacity}`,
        cursor: "default",
      };
    case "black":
      return {
        bgColor: `${theme.palette.black.darkSeaBlack} !important`,
        bgColorHover: `${theme.palette.grey.white} !important`,
        textColor: `${theme.palette.grey.white} !important`,
        textColorHover: `${theme.palette.black.darkSeaBlack} !important`,
        border: `${theme.palette.black.darkSeaBlack} !important`,
        borderColorHover: `${theme.palette.black.darkSeaBlack} !important`,
      };
    case "blackDisabled":
      return {
        bgColor: `${theme.palette.black.darkSeaBlack}${opacity}`,
        textColor: `${theme.palette.grey.white}${opacity}`,
        cursor: "default",
      };
    case "inverted":
      return {
        bgColor: theme.palette.grey.white,
        bgColorHover: theme.palette.black.darkSeaBlack,
        textColor: theme.palette.black.darkSeaBlack,
        textColorHover: theme.palette.grey.white,
        border: theme.palette.black.darkSeaBlack,
        borderColorHover: theme.palette.black.darkSeaBlack,
      };
    case "invertedDisabled":
      return {
        bgColor: theme.palette.grey.white,
        textColor: `${theme.palette.black.darkSeaBlack}${opacity}`,
        border: `${theme.palette.black.darkSeaBlack}${opacity}`,
        borderColorHover: `${theme.palette.black.darkSeaBlack}${opacity}`,
        cursor: "default",
      };
    case "ghost":
      return {
        bgColor: theme.palette.grey.white,
        bgColorHover: theme.palette.black.paleSilver,
        textColor: theme.palette.black.darkSeaBlack,
        textColorHover: theme.palette.black.darkSeaBlack,
        border: theme.palette.grey.white,
        borderColorHover: theme.palette.black.paleSilver,
      };
    case "ghostWhite":
      return {
        bgColor: "transparent",
        bgColorHover: theme.palette.grey.white,
        textColor: theme.palette.grey.white,
        textColorHover: theme.palette.black.darkSeaBlack,
        border: "transparent",
        borderColorHover: theme.palette.black.darkSeaBlack,
      };
    case "ghostDisabled":
      return {
        bgColor: theme.palette.grey.white,
        textColor: `${theme.palette.black.darkSeaBlack}${opacity}`,
        border: theme.palette.grey.white,
        borderColorHover: theme.palette.grey.white,
        cursor: "default",
      };
    case "negative":
      return {
        bgColor: theme.palette.status.errorDark,
        bgColorHover: theme.palette.grey.white,
        textColor: theme.palette.grey.white,
        textColorHover: theme.palette.status.errorDark,
        borderColorHover: theme.palette.status.errorDark,
        border: theme.palette.status.errorDark,
      };
    case "secondary":
      return {
        bgColor: theme.palette.secondary.lavender,
        bgColorHover: theme.palette.secondary.lightLavender,
        textColor: theme.palette.black.darkSeaBlack,
        textColorHover: theme.palette.black.darkSeaBlack,
        borderColorHover: theme.palette.secondary.lightLavender,
        border: theme.palette.secondary.lavender,
      };
    default:
      return {
        bgColor: theme.palette.black.darkSeaBlack,
        bgColorHover: theme.palette.grey.white,
        textColor: theme.palette.grey.white,
        textColorHover: theme.palette.black.darkSeaBlack,
        borderColorHover: theme.palette.black.darkSeaBlack,
      };
  }
};

const buttonStyle = (
  look,
  small,
  size,
  noIcon,
  addtionalStyle,
  marginLeft,
  marginRight
) => ({
  buttonRoot: () => {
    const colors = getColors(look);

    return {
      "&.MuiButtonBase-root": {
        display: "inline-flex",
        justifyContent: noIcon ? "center" : "space-between",
        backgroundColor: colors.bgColor,
        color: colors.textColor,
        height: "50px",
        marginLeft: marginLeft ? "10px" : 0,
        marginRight: marginRight ? "10px" : 0,
        width: "fit-content",
        minWidth: {
          xs: "105px",
          sm: small == true ? "105px" : size || "164px",
        },
        textAlign: "center",
        fontSize: {
          xs: "12px",
          sm: "14px",
        },
        fontWeight: 400,
        textTransform: "none",
        cursor: colors.cursor ? colors.cursor : "pointer",
        border: colors.border
          ? `1px solid ${colors.border}`
          : "1px solid transparent",
        //fontFamily: "inherit",
        lineHeight: "1.14",
        boxShadow: "none",
        borderRadius: 0,
        ...addtionalStyle,
      },
      "&.MuiSvgIcon-root": {
        fontSize: "16px",
      },
      "&:hover": {
        background: look != "youtube" && colors.bgColorHover,
        color: look != "youtube" && colors.textColorHover,
        border: colors.borderColorHover
          ? `1px solid ${colors.borderColorHover}`
          : "1px solid transparent",
        boxShadow: "none",
      },
    };
  },
});

const AppButton = ({
  externalLink,
  internalLink,
  url,
  label,
  look,
  customIcon,
  noIcon,
  small,
  size,
  downloadFunction,
  submittingState,
  type,
  isDisabled,
  addtionalStyle = {},
  onClick,
  targetNewWidow,
  form,
  marginLeft,
  marginRight,
}) => {
  const ButtonComponent = ({ buttonLink }) => {
    return (
      <Button
        variant="contained"
        download={downloadFunction}
        onClick={onClick}
        href={buttonLink}
        target={targetNewWidow ? "_blank" : "_self"}
        form={form}
        type={type}
        disabled={isDisabled || submittingState}
        sx={
          buttonStyle(
            look,
            small,
            size,
            noIcon,
            addtionalStyle,
            marginLeft,
            marginRight
          ).buttonRoot
        }
        endIcon={
          customIcon ? (
            customIcon
          ) : noIcon == true || submittingState ? (
            ""
          ) : (
            <ArrowForwardIcon />
          )
        }
      >
        {submittingState ? <LoadingEllipsis /> : label}
      </Button>
    );
  };
  ButtonComponent.propTypes = {
    buttonLink: PropTypes.string,
  };
  ButtonComponent.defaultProps = {
    buttonLink: undefined,
  };

  return internalLink ? (
    <Link
      to={url}
      target={targetNewWidow ? "_blank" : "_self"}
      style={{ textDecoration: "none" }}
    >
      <ButtonComponent />
    </Link>
  ) : externalLink ? (
    <ButtonComponent buttonLink={url} />
  ) : (
    <ButtonComponent />
  );
};

AppButton.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  look: PropTypes.string,
  size: PropTypes.string,
  downloadFunction: PropTypes.bool,
  onClick: PropTypes.func,
  targetNewWidow: PropTypes.bool,
  noIcon: PropTypes.bool,
  form: PropTypes.string,
  submittingState: PropTypes.bool,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  addtionalStyle: PropTypes.object,
  internalLink: PropTypes.bool,
  externalLink: PropTypes.bool,
  customIcon: PropTypes.node,
  small: PropTypes.bool,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
};
AppButton.defaultProps = {
  url: undefined,
  label: undefined,
  look: undefined,
  size: null,
  downloadFunction: false,
  submittingState: false,
  isDisabled: false,
  targetNewWidow: false,
  noIcon: false,
  form: "",
  type: "button",
  onClick: undefined,
  small: false,
  addtionalStyle: {},
  internalLink: false,
  externalLink: false,
  customIcon: null,
  marginLeft: false,
  marginRight: false,
};

export default AppButton;
